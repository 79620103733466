import styled from 'styled-components';

export const Title = styled.header`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  nav {
    display: flex;
    align-items: center;

    strong {
      margin-left: 8px;
    }

    svg {
      margin-left: 0px !important;
    }
  }

  > svg {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.lightBorder};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
  border-radius: 8px;
  transition: transform 0.25s ease-in-out;

  ${props =>
    props.large
      ? `
        position: fixed;
        top: 50%;
        left: 50%;
        width: 700px;
        height: 500px;
        padding: 20px;
        transform: ${
          props.visible ? 'translate(-50%, -50%)' : 'translate(-50%, -150%)'
        };
        display: ${props.visible ? '' : 'none'};
        transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
      `
      : `
        position: fixed;
        right: 0;
        margin-right: 10px;
        top: 45px;
        width: 330px;
        padding: 5px 10px;
        transform: ${props.visible ? 'translateX(0)' : 'translateX(340px)'};
      `}
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  // flex-direction: column;
  align-items: center;

  form {
    width: 100%;
    height: 100%;

    section {
      width: 100%;
      height: 350px;
      margin-bottom: 15px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .token,
      .session {
        width: 90%;
      }

      .year {
        margin-left: 16px;
      }
    }

    // button {
    //   width: 100%;
    //   background: ${({ theme }) => theme.colors.success};
    //   border-radius: 4px;
    //   color: ${({ theme }) => theme.colors.white};
    //   transition: background 0.2s;
    //   padding: 6px 0;
    //   font-size: 16px;

    //   &:hover {
    //     background: ${({ theme }) => theme.hover.success};
    //   }
    // }

    .popupButton {
      width: 100%;
      background: ${({ theme }) => theme.colors.success};
      border-radius: 4px;
      color: ${({ theme }) => theme.colors.white};
      transition: background 0.2s;
      padding: 6px 0;
      font-size: 16px;

      &:hover {
        background: ${({ theme }) => theme.hover.success};
      }
    }
  }
`;
