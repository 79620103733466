import styled from 'styled-components';
import { Form } from '@unform/web';

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  .pay_status,
  .situation,
  .drawee {
    width: 33%;
  }

  > div + div {
    margin-left: 16px;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 4%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  .table {
    .situation {
      width: 20%;
    }
  }

  .id {
    padding-left: 8px;
  }

  .expiration_date {
    width: 10%;
  }

  .financial_data {
    width: 20%;
  }

  .title_number {
    width: 15%;
  }

  .drawee {
    width: 25%;
  }

  .price {
    width: 10%;
  }
`;

export const BilletInfo = styled.div`
  display: flex;
  flex-direction: column;

  .finance_header,
  .situation_header {
    margin-top: 10px;
  }

  .expiration_date,
  .emition_date,
  .price,
  .interest,
  .interest_type,
  .fine,
  .fine_type,
  .days_to_fine {
    width: 10%;
  }

  .protest,
  .days_to_protest {
    width: 25%;
  }

  .drawee,
  .finance_name_label,
  .financial_data,
  .situation_text {
    width: 35%;
  }

  .message,
  .obs,
  .billet_url {
    width: 100%;
  }
`;
